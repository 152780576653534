'use client';

import { Popover } from '@headlessui/react';
import { AnimatePresence, motion } from 'framer-motion';
import Link from 'next/link';

import { LandingPageButton } from '@/components/LandingPage/Button';
import { Container } from '@/components/LandingPage/Container';
import { NavLinks } from '@/components/LandingPage/NavLinks';

function MenuIcon(props: React.ComponentPropsWithoutRef<'svg'>)
{
	return (
		<svg viewBox='0 0 24 24' fill='none' aria-hidden='true' {...props}>
			<path d='M5 6h14M5 18h14M5 12h14' strokeWidth={2} strokeLinecap='round' strokeLinejoin='round' />
		</svg>
	);
}

function ChevronUpIcon(props: React.ComponentPropsWithoutRef<'svg'>)
{
	return (
		<svg viewBox='0 0 24 24' fill='none' aria-hidden='true' {...props}>
			<path d='M17 14l-5-5-5 5' strokeWidth={2} strokeLinecap='round' strokeLinejoin='round' />
		</svg>
	);
}

function MobileNavLink(props: Omit<React.ComponentPropsWithoutRef<typeof Popover.Button<typeof Link>>, 'as' | 'className'>)
{
	return <Popover.Button as={Link} className='block text-base leading-7 tracking-tight text-gray-700' {...props} />;
}

export function Header()
{
	return (
		<header>
			<nav>
				<Container className='relative z-50 flex justify-between py-8'>
					<div className='relative z-10 flex items-center gap-16'>
						<Link href='/' aria-label='Home' className='text-3xl font-bold'>
							<img src='/logo_horizontal.svg' className='h-8' />
						</Link>
					</div>
					<div className='flex items-center gap-4'>
						<div className='hidden lg:flex lg:gap-10 pr-4 text-lg font-normal'>
							<NavLinks />
						</div>
						<Popover className='lg:hidden'>
							{({ open }) => (
								<>
									<Popover.Button
										className='relative z-10 -m-2 inline-flex items-center rounded-lg stroke-gray-900 p-2 hover:bg-gray-200/50 hover:stroke-gray-600 active:stroke-gray-900 ui-not-focus-visible:outline-none'
										aria-label='Toggle site navigation'
									>
										{({ open }) => (open ? <ChevronUpIcon className='h-6 w-6' /> : <MenuIcon className='h-6 w-6' />)}
									</Popover.Button>
									<AnimatePresence initial={false}>
										{open && (
											<>
												<Popover.Overlay
													static
													as={motion.div}
													initial={{ opacity: 0 }}
													animate={{ opacity: 1 }}
													exit={{ opacity: 0 }}
													className='fixed inset-0 z-0 bg-gray-300/60 backdrop-blur'
												/>
												<Popover.Panel
													static
													as={motion.div}
													initial={{ opacity: 0, y: -32 }}
													animate={{ opacity: 1, y: 0 }}
													exit={{
														opacity: 0,
														y: -32,
														transition: { duration: 0.2 }
													}}
													className='absolute inset-x-0 top-0 z-0 origin-top rounded-b-2xl bg-gray-50 px-6 pb-6 pt-12 shadow-2xl shadow-gray-900/20'
												>
													<div className='mt-8 flex flex-col gap-2'>
														<LandingPageButton
															//href='https://forms.gle/YK55KNcyS3B7Ps7P7'
															href='https://auth.mavenbio.com/'
															variant='outline'
															className=' text-base '
														>
															Log in
														</LandingPageButton>
														<LandingPageButton
															//href='https://forms.gle/YK55KNcyS3B7Ps7P7' /
															//href='/dashboard'
															href='https://cal.com/team/maven-bio/intro'
															className='bg-maven-primary-600 hover:bg-maven-primary-500 active:bg-maven-primary-400 font-semibold text-base '
														>
															Get a demo
														</LandingPageButton>
														{/* <Button
															//href='https://forms.gle/YK55KNcyS3B7Ps7P7'
															href='https://cal.com/team/maven-bio/intro'
															variant='outline'
														>
															Request a Trial
														</Button> */}
													</div>
												</Popover.Panel>
											</>
										)}
									</AnimatePresence>
								</>
							)}
						</Popover>
						<LandingPageButton
							href='https://auth.mavenbio.com/'
							variant='outline'
							className='hidden text-base lg:block font-semibold rounded-full px-4 py-2 text-nowrap 
								border-2 border-maven-primary-600 text-maven-primary-600
								hover:bg-maven-neutral-50 hover:border-maven-primary-500 hover:text-maven-primary-500
								active:bg-maven-primary-100 active:border-maven-primary-700 active:text-maven-primary-700
								transition-all duration-300'
						>
							Sign in
						</LandingPageButton>
						<LandingPageButton
							href='https://cal.com/team/maven-bio/intro'
							className='hidden lg:block text-base font-bold rounded-full px-4 py-2 text-nowrap text-white border-2 border-maven-primary-600
								bg-maven-primary-600 
								hover:bg-maven-primary-500 hover:border-maven-primary-500
								active:bg-maven-primary-700 active:border-maven-primary-700
								transition-all duration-300'
						>
							Book a demo
						</LandingPageButton>
					</div>
				</Container>
			</nav>
		</header>
	);
}
